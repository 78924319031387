import { Routes } from '@angular/router'

import { ClinicExistsGuard } from './core/guards/clinic.guard'

export const AppRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login',
  },
  {
    path: 'kiosk',
    loadChildren: () => import('./public/kiosk/kiosk.module').then(m => m.KioskModule),
  },
  {
    path: 'patient',
    canActivate: [
      ClinicExistsGuard,
    ],
    loadChildren: () => import('./patient/patient.module').then(m => m.PatientModule),
  },
  {
    path: 'tablet',
    canActivate: [
      ClinicExistsGuard,
    ],
    loadChildren: () => import('./patient/modules/tablet/tablet.module').then(m => m.TabletModule),
  },
  // {
  //   path: 'public',
  //   canActivate: [
  //     ClinicExistsGuard,
  //   ],
  //   loadChildren: () => import('./public/home/home.module').then(m => m.HomeModule),
  // },
  { // load a custom 404 error page for anything else
    path: '',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
  },
]
