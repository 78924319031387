import { AfterViewInit, Component, OnInit } from '@angular/core'
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router'
import { OfflineService } from 'app/core/services/offline.service'
import { environment } from 'environments/environment'
import { isFunction, isString } from 'lodash'

import { NotificationService } from './core/services/notification.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: false,
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    public offline: OfflineService,
    private router: Router,
    protected notificationService: NotificationService,
  ) {
    const trackingId = environment.googleAnalyticsId

    // Redirection for users under IE
    if (typeof document['documentMode'] === 'number') {
      this.router.navigate(['/ie'])
    }

    if (isString(trackingId) && trackingId.length > 0) {
      this.router.events.subscribe(event => {
        const ga = (window as any).ga

        if (event instanceof NavigationEnd && isFunction(ga)) {
          ga('set', 'page', event.urlAfterRedirects)
          ga('send', 'pageview')
        }
      })
    }
  }

  ngOnInit() {
    const appLoader = document.getElementById('app-loader')

    if (appLoader) {
      appLoader.style.display = 'none'
    }
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        document.getElementById('app-loader').style.display = 'flex'
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        document.getElementById('app-loader').style.display = 'none'
      }
    })
  }
}
